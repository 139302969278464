import isSet from '@snipsonian/core/es/is/isSet';
import isArray from '@snipsonian/core/es/is/isArray';
import isBoolean from '@snipsonian/core/es/is/isBoolean';
import { createActionHandlersForType } from '@snipsonian/redux/es/reducer/createActionHandlersForType';
import { ICreateNewState, IShouldRefreshDataPayload } from '../../../../models/general/redux';
import { REDUCER_KEYS } from '../../../../config/redux.config';

export const PAYLOAD_PARAM = {
    /**
     * Usage:
     * - e.g. If you want to re-trigger the current route action to add a query param to the url, but
     *   without retriggering the related api call (and without activating the loader), you can add
     *   the boolean 'shouldRefreshData: false' to the route action payload.
     */
    SHOULD_REFRESH_DATA: 'shouldRefreshData',
    NO_DATA_REFRESH_EPIC_IDS: 'noDataRefreshEpicIds',
};

export function getAsyncFetchInitialState() {
    return {
        data: null,
        isFetching: false,
        error: null,
    };
}

export function getAsyncDoInitialState() {
    return {
        isDoing: false,
        isDone: false,
        error: null,
    };
}

interface ICreateAsyncBaseActionHandlersConfig<ReducerState> {
    baseActionType: string;
    fieldName: keyof ReducerState;
    overrideTriggerActionType?: string | string[];
    overrideSuccessActionType?: string;
    overrideFailActionType?: string;
    overrideCancelActionType?: string;
    overrideResetType?: string;
    transformStateOnTrigger?: ICreateNewState<ReducerState, object>;
    transformStateOnSuccess?: ICreateNewState<ReducerState, object>;
    transformStateOnFail?: ICreateNewState<ReducerState, object>;
    transformStateOnCancel?: ICreateNewState<ReducerState, object>;
    transformStateOnReset?: ICreateNewState<ReducerState, object>;
}

export interface ICreateAsyncFetchActionHandlersConfig<Data, ReducerState, SuccessActionPayload>
    extends ICreateAsyncBaseActionHandlersConfig<ReducerState> {
    mapSuccessPayload?: (payload: SuccessActionPayload) => Data | SuccessActionPayload;
    resetDataOnTrigger?: boolean;
    resetDataOnError?: boolean;
    extraAsyncFetchFieldNamesToResetOnTrigger?: (keyof ReducerState)[];
    extraAsyncDoFieldNamesToResetOnTrigger?: (keyof ReducerState)[];
    reducerKey: REDUCER_KEYS;
}

export interface ICreateAsyncDoActionHandlersConfig<ReducerState>
    extends ICreateAsyncBaseActionHandlersConfig<ReducerState> {
    fieldNameToClearOnSuccess?: keyof ReducerState;
    extraAsyncDoFieldNamesToResetOnTrigger?: (keyof ReducerState)[];
}

export function createAsyncFetchActionHandlers<Data, ReducerState, SuccessActionPayload extends object = {}>({
    baseActionType,
    fieldName,
    overrideTriggerActionType,
    overrideSuccessActionType,
    overrideFailActionType,
    overrideCancelActionType,
    overrideResetType,
    mapSuccessPayload = (payload) => payload,
    // resetDataOnTrigger = true,
    resetDataOnError = true,
    // extraAsyncFetchFieldNamesToResetOnTrigger = [],
    // extraAsyncDoFieldNamesToResetOnTrigger = [],
    transformStateOnTrigger,
    transformStateOnSuccess,
    transformStateOnFail,
    transformStateOnCancel,
    transformStateOnReset,
    // reducerKey,
}: ICreateAsyncFetchActionHandlersConfig<Data, ReducerState, SuccessActionPayload>) {
    return createActionHandlersForType<ReducerState>(baseActionType)
        .onTrigger(
            ({ oldState, payload }) => {
                const shouldRefresh = shouldDataBeRefreshed(payload);

                const newState = Object.assign({}, oldState);

                // Check if it's just a filter change, not a full data fetch
                if (!shouldRefresh) {
                    // Avoid setting isFetching to true if no data refresh is needed (filter change)
                    newState[fieldName] = {
                        ...newState[fieldName],
                        isFetching: false, // Prevent loader
                        error: null, // Clear any previous error
                    };
                } else {
                    // Proceed with the existing logic to handle state when data should be fetched
                    newState[fieldName] = {
                        ...newState[fieldName],
                        isFetching: true, // Set loader if fetching data
                        error: null, // Clear any previous error
                    };
                }

                // Apply any other existing transformations on the state
                if (typeof transformStateOnTrigger === 'function') {
                    return transformStateOnTrigger({ oldState: newState, payload });
                }

                return newState;
            },
            overrideTriggerActionType,
        )
        .onSuccess<SuccessActionPayload>(
            ({ oldState, payload }) => {
                const stateAfterSuccess = Object.assign(
                    {},
                    oldState,
                    {
                        [fieldName]: {
                            ...oldState[fieldName as string],
                            data: mapSuccessPayload(payload),
                            isFetching: false,
                            error: null,
                        },
                    },
                );

                if (typeof transformStateOnSuccess === 'function') {
                    return transformStateOnSuccess({
                        oldState: stateAfterSuccess,
                        payload,
                    });
                }
                return stateAfterSuccess;
            },
            overrideSuccessActionType,
        )
        .onFail<Error>(
            ({ oldState, payload }) => {
                const stateAfterFail = Object.assign(
                    {},
                    oldState,
                    {
                        [fieldName]: {
                            ...oldState[fieldName as string],
                            isFetching: false,
                            error: payload,
                            data: resetDataOnError ? null : oldState[fieldName as string].data,
                        },
                    },
                );

                if (typeof transformStateOnFail === 'function') {
                    return transformStateOnFail({
                        oldState: stateAfterFail,
                        payload,
                    });
                }
                return stateAfterFail;
            },
            overrideFailActionType,
        )
        .onCancel(
            ({ oldState, payload }) => {
                const stateAfterCancel = Object.assign(
                    {},
                    oldState,
                    {
                        [fieldName]: {
                            ...oldState[fieldName as string],
                            isFetching: false,
                        },
                    },
                );

                if (typeof transformStateOnCancel === 'function') {
                    return transformStateOnCancel({
                        oldState: stateAfterCancel,
                        payload,
                    });
                }
                return stateAfterCancel;
            },
            overrideCancelActionType,
        )
        .onReset(
            ({ oldState, payload }) => {
                const stateAfterReset = Object.assign(
                    {},
                    oldState,
                    {
                        [fieldName]: getAsyncFetchInitialState(),
                    },
                );

                if (typeof transformStateOnReset === 'function') {
                    return transformStateOnReset({
                        oldState: stateAfterReset,
                        payload,
                    });
                }
                return stateAfterReset;
            },
            overrideResetType,
        )
        .create();
}

export function createAsyncDoActionHandlers<ReducerState>({
    baseActionType,
    fieldName,
    overrideTriggerActionType,
    overrideSuccessActionType,
    overrideFailActionType,
    overrideCancelActionType,
    overrideResetType,
    fieldNameToClearOnSuccess,
    transformStateOnTrigger,
    transformStateOnSuccess,
    transformStateOnFail,
    transformStateOnCancel,
    transformStateOnReset,
    extraAsyncDoFieldNamesToResetOnTrigger = [],
}: ICreateAsyncDoActionHandlersConfig<ReducerState>) {
    return createActionHandlersForType<ReducerState>(baseActionType)
        .onTrigger(
            ({ oldState, payload }) => {
                const stateAfterTrigger = Object.assign(
                    {},
                    oldState,
                    {
                        [fieldName]: {
                            ...oldState[fieldName as string],
                            isDoing: true,
                            isDone: false,
                            error: null,
                        },
                    },
                );

                const stateAfterDoReset = extraAsyncDoFieldNamesToResetOnTrigger
                    .reduce(
                        (newStateAccumulator, doFieldNameToReset) => (
                            Object.assign(
                                newStateAccumulator,
                                {
                                    [doFieldNameToReset]: getAsyncDoInitialState(),
                                },
                            )
                        ),
                        stateAfterTrigger,
                    );

                if (typeof transformStateOnTrigger === 'function') {
                    return transformStateOnTrigger({
                        oldState: stateAfterDoReset,
                        payload,
                    });
                }
                return stateAfterTrigger;
            },
            overrideTriggerActionType,
        )
        .onSuccess(
            ({ oldState, payload }) => {
                const stateAfterSuccess = Object.assign(
                    {},
                    oldState,
                    {
                        [fieldName]: {
                            ...oldState[fieldName as string],
                            isDone: true,
                            isDoing: false,
                        },
                    },
                );
                if (fieldNameToClearOnSuccess) {
                    stateAfterSuccess[fieldNameToClearOnSuccess as string] = null;
                }
                if (typeof transformStateOnSuccess === 'function') {
                    return transformStateOnSuccess({
                        oldState: stateAfterSuccess,
                        payload,
                    });
                }
                return stateAfterSuccess;
            },
            overrideSuccessActionType,
        )
        .onFail<Error>(
            ({ oldState, payload }) => {
                const stateAfterFail = Object.assign(
                    {},
                    oldState,
                    {
                        [fieldName]: {
                            ...oldState[fieldName as string],
                            isDoing: false,
                            isDone: false,
                            error: payload,
                        },
                    },
                );

                if (typeof transformStateOnFail === 'function') {
                    return transformStateOnFail({
                        oldState: stateAfterFail,
                        payload,
                    });
                }
                return stateAfterFail;
            },
            overrideFailActionType,
        )
        .onCancel(
            ({ oldState, payload }) => {
                const stateAfterCancel = Object.assign(
                    {},
                    oldState,
                    {
                        [fieldName]: {
                            ...oldState[fieldName as string],
                            isDoing: false,
                        },
                    },
                );

                if (typeof transformStateOnCancel === 'function') {
                    return transformStateOnCancel({
                        oldState: stateAfterCancel,
                        payload,
                    });
                }
                return stateAfterCancel;
            },
            overrideCancelActionType,
        )
        .onReset(
            ({ oldState, payload }) => {
                const stateAfterReset = Object.assign(
                    {},
                    oldState,
                    {
                        [fieldName]: getAsyncDoInitialState(),
                    },
                );

                if (typeof transformStateOnReset === 'function') {
                    return transformStateOnReset({
                        oldState: stateAfterReset,
                        payload,
                    });
                }
                return stateAfterReset;
            },
            overrideResetType,
        )
        .create();
}

export function shouldDataBeRefreshed(payload: object) {
    const shouldRefreshData = (payload as IShouldRefreshDataPayload).shouldRefreshData;
    return (isSet(shouldRefreshData) && isBoolean(shouldRefreshData))
        ? shouldRefreshData
        : true;
}

export function shouldDataBeRefreshedByReducer(payload: object, reducerKey: REDUCER_KEYS) {
    if (shouldDataBeRefreshed(payload)) {
        return true;
    }

    const noDataRefreshOnlyInTheseReducers = (payload as IShouldRefreshDataPayload).noDataRefreshOnlyInTheseReducers;
    return (isSet(noDataRefreshOnlyInTheseReducers) && isArray(noDataRefreshOnlyInTheseReducers))
        ? !noDataRefreshOnlyInTheseReducers.includes(reducerKey)
        : false;
}
