import React, { useState, useEffect } from 'react';

import { AsyncStatus } from '../../../../models/general/redux';
import {
    getReservedMedicalExaminations,
    getReservedMedicalExaminationsAsyncInfo,
} from '../../../../redux/medicalExamination/selectors';
import { navigateTo } from '../../../../redux/location/actions';
import ROUTE_KEYS from '../../../../routeKeys';
import { connect } from '../../../index';
import planBufferzoneIcon from '../../../assets/img/icons/top-tasks/plan-bufferzone.svg';
import planEmployeeIcon from '../../../assets/img/icons/top-tasks/plan-single-employee.svg';
import planPOIcon from '../../../assets/img/icons/top-tasks/plan-periodic-medical-examination.svg';
import ErrorPlaceholder from '../../../common/error/ErrorPlaceholder';
import Icon from '../../../common/icons/Icon';
import Loader from '../../../common/waiting/Loader';
import PageHeader from '../../../appShell/PageHeader';
import Tile from '../../../common/widget/Tile';
import TooltipWithIcon from '../../../common/widget/TooltipWithIcon';
import Translate from '../../../common/Translate';

import { CLASS_NAME, TRANSLATION_PREFIX } from './SelectBufferzoneOption.const';
import { ISelectBufferzoneOptionPrivateProps } from './SelectBufferzoneOption.type';

import '../shared/plan-exam-tile-select.scss';
import './SelectBufferzoneOption.scss';

const infoIcon = (
    <Icon typeName="info" circle />
);

const SelectBufferzoneOptionComponent = ({
    bufferzonesAsyncInfo,
    hasBufferzones,
    navigateToBufferzoneOverview,
    navigateToSelectExaminationReason,
    navigateToToPlanEmployees,
}: ISelectBufferzoneOptionPrivateProps) => {
    const [showContent, setShowContent] = useState<boolean>(false);

    useEffect(() => {
        setShowContent(bufferzonesAsyncInfo.status === AsyncStatus.Success);
    }, [bufferzonesAsyncInfo, setShowContent]);

    return (
        <div className={`${CLASS_NAME} tile-dashboard`}>
            { showContent && <PageHeader title={`${TRANSLATION_PREFIX}.title`} /> }
            <div className="container">
                { bufferzonesAsyncInfo.error && <ErrorPlaceholder apiError={bufferzonesAsyncInfo.error} /> }

                <Loader show={bufferzonesAsyncInfo.status} />

                { showContent && (
                        <div className="tiles"><div className="tile-wrapper">
                            <Tile
                                title={`${TRANSLATION_PREFIX}.tile.po.title`}
                                svg={planPOIcon}
                                typeName="white"
                                onClick={navigateToToPlanEmployees}
                                tooltipWithIcon={
                                    <TooltipWithIcon
                                        icon={infoIcon}
                                        typeName="info-bubble"
                                    >
                                        <Translate msg={`${TRANSLATION_PREFIX}.tile.po.tooltip`} />
                                    </TooltipWithIcon>
                                }
                            />
                        </div>
                        {hasBufferzones && (
                            <>
                                <div className="tile-wrapper">
                                    <Tile
                                        className={`${CLASS_NAME}__plan-bufferzone-tile`}
                                        title={`${TRANSLATION_PREFIX}.tile.bufferzone.title`}
                                        svg={planBufferzoneIcon}
                                        typeName="white"
                                        onClick={navigateToBufferzoneOverview}
                                        tooltipWithIcon={
                                            <TooltipWithIcon icon={infoIcon} typeName="info-bubble">
                                                <Translate msg={`${TRANSLATION_PREFIX}.tile.bufferzone.tooltip`} />
                                            </TooltipWithIcon>
                                        }
                                    />
                                </div>
                                <p className={`${CLASS_NAME}__mobile-message`}>
                                    <Icon typeName="warning-triangle" colorType="warning" />
                                    <span>
                                        <Translate msg={`${TRANSLATION_PREFIX}.plan_bufferzone_disabled_for_mobile`} />
                                    </span>
                                </p>
                            </>
                        )}
                        <div className="tile-wrapper">
                            <Tile
                                title={`${TRANSLATION_PREFIX}.tile.regular.title`}
                                svg={planEmployeeIcon}
                                typeName="white"
                                onClick={navigateToSelectExaminationReason}
                                tooltipWithIcon={
                                    <TooltipWithIcon
                                        icon={infoIcon}
                                        typeName="info-bubble"
                                    >
                                        <Translate msg={`${TRANSLATION_PREFIX}.tile.regular.tooltip`} />
                                    </TooltipWithIcon>
                                }
                            />
                        </div>
                    </div>
                ) }

            </div>
        </div>
    );
};

const SelectBufferzoneOption = connect<ISelectBufferzoneOptionPrivateProps>({
    stateProps: (state) => {
        return {
            bufferzonesAsyncInfo: getReservedMedicalExaminationsAsyncInfo(state),
            hasBufferzones: getReservedMedicalExaminations(state).length > 0,
        };
    },
    dispatchProps: (dispatch) => {
        return {
            navigateToBufferzoneOverview: () => {
                dispatch(navigateTo(ROUTE_KEYS.R_MEDICAL_EXAMINATIONS_BUFFERZONES));
            },
            navigateToSelectExaminationReason: () => {
                dispatch(navigateTo(ROUTE_KEYS.R_MEDICAL_EXAMINATIONS_NEW_SELECT_REASON));
            },
            navigateToToPlanEmployees: () => {
                dispatch(navigateTo(ROUTE_KEYS.R_MEDICAL_EXAMINATIONS_TO_PLAN));
            },
        };
    },
})(SelectBufferzoneOptionComponent);

export { SelectBufferzoneOption };
