import React from 'react';

import { ISortedColumn, ListColumns, ListItem, SortOrder, SortType } from '../../../../../models/general/list';
import Translate from '../../../../common/Translate';

import { IColumnNames } from './PlannedMedicalExaminations.type';

export const BASE_NAME = 'planned-med-exams';
export const DEFAULT_NR_OF_RECORDS_TO_SHOW = 20;
export const CLASS_NAME = 'MedicalExaminationsPlanned';
export const TRANSLATION_PREFIX = 'interventions.medical_examinations.planned';
export const EXPORT_BASE_FILENAME = 'medical-examinations-planned';

export const COLUMNS: ListColumns<IColumnNames> = {
    employee: {
        label: <Translate msg={`${TRANSLATION_PREFIX}.columns.employee`} />,
        sortable: true,
        sortType: SortType.String,
        percentWidth: 20,
    },
    examinationReason: {
        label: <Translate msg={`${TRANSLATION_PREFIX}.columns.examination_reason`} />,
        sortable: true,
        sortType: SortType.String,
        percentWidth: 25,
    },
    examinationReasonId: {
        hide: true,
        percentWidth: null,
    },
    examinationReasonCode: {
        hide: true,
        percentWidth: null,
    },
    location: {
        label: <Translate msg={`${TRANSLATION_PREFIX}.columns.location`} />,
        sortable: true,
        sortType: SortType.String,
        percentWidth: 28,
    },
    locationId: {
        hide: true,
        percentWidth: null,
    },
    employeeCustomerId: {
        hide: true,
        percentWidth: null,
    },
    day: {
        label: <Translate msg={`${TRANSLATION_PREFIX}.columns.day`} />,
        sortable: true,
        sortType: SortType.String,
        sortValue: (listItem: ListItem<IColumnNames>) => listItem.columns.daySort,
        percentWidth: 10,
    },
    daySort: {
        hide: true,
        sortType: SortType.String,
        percentWidth: null,
    },
    hour: {
        label: <Translate msg={`${TRANSLATION_PREFIX}.columns.hour`} />,
        sortable: true,
        sortType: SortType.String,
        percentWidth: 7,
    },
    employmentId: {
        hide: true,
        percentWidth: null,
    },
    planningRequestId: {
        hide: true,
        percentWidth: null,
    },
    actions: {
        sortable: false,
        percentWidth: 10,
    },
};

export const INITIAL_SORT: ISortedColumn<IColumnNames> = {
    name: 'day',
    sortOrder: SortOrder.Ascending,
};
