import React, { PureComponent, MouseEvent } from 'react';
import { clone } from 'ramda';

import {
    getCreateConvocationsAsyncInfo,
    getDocumentAsyncInfo,
} from '../../../../../../redux/employee/documents/selectors';
import { isInThePast } from '../../../../../../utils/core/date/isInThePast';
import { ListItem } from '../../../../../../models/general/list';
import { MAX_NR_OF_DOCUMENT_DOWNLOADS } from '../../../../../../config/general.config';
import Button from '../../../../../common/buttons/Button';
import ErrorPlaceholder from '../../../../../common/error/ErrorPlaceholder';
import HelpTooltip from '../../../../../common/widget/HelpTooltip';
import Icon from '../../../../../common/icons/Icon';
import ListItemActions from '../../../../../common/list/ListItemActions';
import ListWithCheckboxSelect from '../../../../../common/list/ListWithCheckboxSelect';
import Loader from '../../../../../common/waiting/Loader';
import NoDocumentsFoundErrorDialog from '../../../../../common/modals/NoDocumentsFoundErrorDialog';
import ShowIfAllowed from '../../../../../auth/ShowIfAllowed';
import Translate from '../../../../../common/Translate';
import EditAppointmentDialog from '../../EditAppointmentDialog';
import {
    BASE_NAME,
    COLUMNS,
    DEFAULT_NR_OF_RECORDS_TO_SHOW,
    INITIAL_SORT,
    TRANSLATION_PREFIX,
} from '../PlannedMedicalExaminations.const';
import { IPrivateProps, ISelectDocumentsProps, IColumnNames } from '../PlannedMedicalExaminations.type';

import { IExaminationsListState, TExaminationsListProps } from './PlannedMedicalExaminationsList.type';

class PlannedMedicalExaminationsList extends
    PureComponent<TExaminationsListProps & IPrivateProps & ISelectDocumentsProps, IExaminationsListState> {

    private columns = clone(COLUMNS);
    private firstEditableIndex: number;

    constructor(props: TExaminationsListProps & IPrivateProps & ISelectDocumentsProps & ISelectDocumentsProps) {
        super(props);

        this.state = {
            isEditAppointmentDialogOpen: false,
            selectedItemId: null,
            isHelpTooltipTemporarilyDismissed: false,
            selectedItem: null,
        };

        this.onDownloadClick = this.onDownloadClick.bind(this);
        this.onEditClick = this.onEditClick.bind(this);
        this.dismissHelpTooltip = this.dismissHelpTooltip.bind(this);

        this.columns.actions.render = (listItem: ListItem<IColumnNames, number>, index) => {
            // Every time a rerender of the columns is done reset the first editable index
            // In case of sorting this can change so we need to recalculate it
            if (index === 0) {
                this.firstEditableIndex = null;
            }

            const pencilIcon = (
                <Icon
                    circle
                    typeName="pencil"
                    onClick={(e) => this.onEditClick(e, listItem)}
                />
            );
            const helpTooltip = (
                <HelpTooltip
                    id="medical_examinations.planned.help.edit"
                    target={pencilIcon}
                    onCloseIntent={this.dismissHelpTooltip}
                >
                    <h4><Translate msg={`${TRANSLATION_PREFIX}.help.edit.title`} /></h4>
                    <p>
                        <Translate
                            msg={`${TRANSLATION_PREFIX}.help.edit.text`}
                            placeholders={{
                                pencilIcon,
                            }}
                        />
                    </p>
                </HelpTooltip>
            );

            const itemIsInThePast = isInThePast(listItem.columns.daySort as string);
            const canHaveHelpTooltip = !itemIsInThePast && !props.selectedItemId;
            if (canHaveHelpTooltip && this.firstEditableIndex === null) {
                this.firstEditableIndex = index;
            }

            const isExaminationCancellable = (code) => {
                const blockedExaminations = [
                    'REINT',
                    'REINTOPV',
                    'REINTTEL',
                    'ART34',
                    'ART34OPV',
                    'ART34TEL',
                    'ART34VWD',
                    'ART34ADM',
                    'REINTADM',
                    'REINTCON',
                ];
                return blockedExaminations.indexOf(code) === -1;
            };

            return (
                <ListItemActions>
                    <Button
                        id="download-file-button"
                        onClick={(e) => this.onDownloadClick(e, listItem)}
                    >
                        <span><Translate msg={`${TRANSLATION_PREFIX}.actions.download_invitation`} /></span>
                        <Icon circle typeName="download-file" />
                    </Button>
                    {isExaminationCancellable(listItem.columns.examinationReasonCode) &&
                        <ShowIfAllowed requiredAccessLevels={{ planning: 'W' }}>
                            {itemIsInThePast ? null : (
                                canHaveHelpTooltip
                                && index === this.firstEditableIndex
                                && !this.state.isHelpTooltipTemporarilyDismissed
                                ? helpTooltip : pencilIcon
                            )}
                        </ShowIfAllowed>
                    }
                </ListItemActions>
            );
        };
    }

    public render() {
        const {
            masterAsyncInfo,
            masterData: clientSideFilteredlistItems,
            selectedIds,
            setSelectedIds,
            setSortedColumn,
            onItemSelected,
            filterValues,
            isDownloadingDocument,
            footer,
        } = this.props;

        const { selectedItem } = this.state;

        return (
            <>
                <EditAppointmentDialog
                    show={this.state.isEditAppointmentDialogOpen}
                    onCloseIntent={() => {
                        this.setState({
                            isEditAppointmentDialogOpen: false,
                            selectedItem: null,
                        });
                    }}
                    plannedMedicalExamination={selectedItem}
                />
                <ListWithCheckboxSelect
                    name={BASE_NAME}
                    withSorting={true}
                    columns={this.columns}
                    items={clientSideFilteredlistItems}
                    selectedItemIds={selectedIds}
                    onItemSelected={setSelectedIds}
                    errorMessage={masterAsyncInfo.error &&
                        <ErrorPlaceholder apiError={masterAsyncInfo.error} />}
                    maxNrOfRecordsToShow={filterValues.isShowAll ? undefined : DEFAULT_NR_OF_RECORDS_TO_SHOW}
                    footer={footer}
                    maxSelectedItems={MAX_NR_OF_DOCUMENT_DOWNLOADS}
                    initialSort={INITIAL_SORT}
                    onColumnSortChanged={setSortedColumn}
                    onItemRowClicked={onItemSelected}
                />
                <NoDocumentsFoundErrorDialog
                    asyncInfoSelector={getCreateConvocationsAsyncInfo}
                />
                <NoDocumentsFoundErrorDialog
                    asyncInfoSelector={getDocumentAsyncInfo}
                />
                <Loader show={isDownloadingDocument} />
            </>
        );
    }

    private dismissHelpTooltip() {
        this.setState({
            isHelpTooltipTemporarilyDismissed: true,
        });
    }

    private onEditClick(e: MouseEvent<HTMLElement>, listItem: ListItem<IColumnNames, number>) {
        e.preventDefault();
        e.stopPropagation();

        const { plannedMedicalExaminations = [] } = this.props;

        this.setState({
            isEditAppointmentDialogOpen: true,
            selectedItemId: listItem.id,
            selectedItem: plannedMedicalExaminations.find((plannedMedicalExamination) => {
                return plannedMedicalExamination.timeSlotId === listItem.id;
            }),
        });
    }

    private onDownloadClick(e: MouseEvent<HTMLElement>, listItem: ListItem<IColumnNames>) {
        e.preventDefault();
        e.stopPropagation();

        const {
            onDownloadClick,
        } = this.props;

        onDownloadClick(listItem);
    }
}

export { PlannedMedicalExaminationsList };
